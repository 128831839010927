import React, { useState } from 'react';
import { Formik } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { ContactService } from 'services';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";

export default function AddContact() {
    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);

    const schema = yup.object().shape({
        type: yup.string().required("Select Type"),
        full_name: yup.string().required("Enter Name"),
        company_name: yup.string(),
        mobile: yup.string().required("Enter Mobile no"),
        mobile2: yup.string(),
        city: yup.string().required("Enter City"),
        state: yup.string().required("Enter State"),
        
    });
    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title">Add Contact</h2>
                <Container>
                <Row>
                    <div className="form-box">
                        <div className="form-body">
                            <Formik
                                validationSchema={schema}
                                onSubmit={
                                    (values) => {
                                        setSubmitting(true);
                                        ContactService.add(values).then(res => {
                                            setSubmitting(false);
                                            let response = res.data;
                                            if (response.success === true) {
                                                navigate('/contacts')
                                            }
                                            /* Alert section start*/
                                            confirmAlert({
                                                title: (response.success === true) ? "Success" : "Failed",
                                                message: response.message,
                                                buttons: [
                                                    {
                                                        label: 'Ok',
                                                        onClick: () => { }
                                                    }
                                                ]
                                            });
                                            /* Alert section end*/
                                        });
                                    }
                                }
                                initialValues={{
                                    type: '0',
                                    full_name: '',
                                    company_name: '',
                                    mobile: '',
                                    mobile2: '',
                                    city: '',
                                    state: ''
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (

                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group as={Col} md="12" controlId="type">
                                            <Form.Label> Type</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="type"
                                                value={values.type}
                                                onChange={handleChange}
                                                isInvalid={!!errors.type}
                                                isValid={touched.type && !errors.type}
                                            >
                                                <option value="0">Transporter</option>
                                                <option value="1">Party</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.type}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="full_name">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Full Name"
                                                name="full_name"
                                                value={values.full_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.full_name}
                                                isValid={touched.full_name && !errors.full_name}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.full_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="company_name">
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Company Name"
                                                name="company_name"
                                                value={values.company_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.company_name}
                                                isValid={touched.company_name && !errors.company_name}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.company_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="mobile">
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Mobile"
                                                name="mobile"
                                                value={values.mobile}
                                                onChange={handleChange}
                                                isInvalid={!!errors.mobile}
                                                isValid={touched.mobile && !errors.mobile}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mobile}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="mobile2">
                                            <Form.Label>Mobile2</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Mobile2"
                                                name="mobile2"
                                                value={values.mobile2}
                                                onChange={handleChange}
                                                isInvalid={!!errors.mobile2}
                                                isValid={touched.mobile2 && !errors.mobile2}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mobile2}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="city">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="City"
                                                name="city"
                                                value={values.city}
                                                onChange={handleChange}
                                                isInvalid={!!errors.city}
                                                isValid={touched.city && !errors.city}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.city}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="state">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="State"
                                                name="state"
                                                value={values.state}
                                                onChange={handleChange}
                                                isInvalid={!!errors.state}
                                                isValid={touched.state && !errors.state}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.state}
                                            </Form.Control.Feedback>
                                        </Form.Group>




                                        {/* <DateTimeField id="datepicker" value={values.payment_at} onChange={handleDateTimeChange} /> */}
                                        <Form.Group as={Col} md="12" controlId="submit">
                                            <Button className="btn btn-dark btn-form-submit w-100 mt-3" type="submit" disabled={submitting}>Save</Button>
                                        </Form.Group>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </Row>
            </Container >
            </section>
        </>
    );
}