import SalaryGenerate from "components/Salary/SalaryGenerate";


export default function GenerateSalary() {

    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title">Generate Salary</h2>
                < SalaryGenerate/>
            </section>
        </>
    );
}