import axios from 'axios'

export const EmployeeService = {
  list,
  activeEmployees,
  addEdit,
  detail
}

function list (data) {
  return axios.post(`employees/list`, data).then()
}
function activeEmployees () {
  return axios.get(`employees/active-employees`).then()
}
function addEdit (data) {
  return axios.post(`employees/add-edit`, data).then()
}
function detail (id) {
  return axios.get(`employees/employee/${id}`).then()
}
