import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BsHouseFill, BsPlusCircleFill, BsFillArrowRightSquareFill, BsFillCalendarFill, BsList, BsFillPersonFill, BsFillPeopleFill, BsX, BsFillArrowUpRightSquareFill, BsFillWalletFill, BsFillMapFill } from "react-icons/bs";
import './ProtectedLayout.scss'
export default function ProtectedLayoutFooter() {

    const { pathname } = useLocation();
    const [showMoreMenu, setShowMoreMenu] = useState(false);
    const [showAddOptions, setShowAddOptions] = useState(false);

    const handleMenuClick = () => {
        setShowMoreMenu(!showMoreMenu);
        setShowAddOptions(false);
    }
    const handleAddClick = () => {
        setShowMoreMenu(false);
        setShowAddOptions(!showAddOptions);
    }

    useEffect(() => {
        setShowMoreMenu(false);
        setShowAddOptions(false);
    }, [pathname]);

    return (
        <div className="footer">
            <nav className="menu">
                <Link to="/" className="menu-item"><BsHouseFill size={30} /> <span>Home</span></Link>
                <Link to="/payments" className="menu-item"><BsFillArrowRightSquareFill size={30} /><span>Payments</span></Link>
                <div className="menu-item" onClick={() => handleAddClick()}> {showAddOptions ? <BsX size={30} /> : <BsPlusCircleFill size={30} />}<span>Add</span></div>
                <Link to="/routes" className="menu-item"><BsFillCalendarFill size={30} /><span>Routes</span></Link>
                <div onClick={() => handleMenuClick()} className="menu-item"> {showMoreMenu ? <BsX size={30} /> : <BsList size={30} />}<span>Menu</span></div>
            </nav>
            <nav className={`more-menu ${!showMoreMenu ? 'd-none' : ''}`}>
                <Link to="/employees"><BsFillPeopleFill size={15} /><span>Employees</span></Link>
                <Link to="/contacts"><BsFillPersonFill size={15} /><span>Contacts</span></Link>
                <Link to="/profile"><BsFillPersonFill size={15} /><span>Profile</span></Link>
            </nav>

            <nav className={`add-options ${!showAddOptions ? 'd-none' : ''}`}>
                <Link to="/add-payment"><BsFillWalletFill size={13} /><span>Payment</span></Link>
                <Link to="/generate-salary"><BsFillWalletFill size={15} /><span>Payroll</span></Link>
                <Link to="/add-route"><BsFillMapFill size={13} /><span>Route</span></Link>
                <Link to="/add-employee"><BsFillPersonFill size={13} /><span>Employee</span></Link>
                <Link to="/add-contact"><BsFillPersonFill size={15} /><span>Contact</span></Link>
            </nav>
        </div>
    );
};