import axios from 'axios'

export const ContactService = {
  list,
  activeContacts,
  add,
  detail,
  transporters
}

function list (data) {
  return axios.post(`contacts/list`, data).then()
}
function activeContacts () {
  return axios.get(`contacts/active-contacts`).then()
}
function add (data) {
  return axios.post(`contacts/add`, data).then()
}
function detail (id) {
  return axios.get(`contacts/${id}`).then()
}
function transporters () {
  return axios.post(`contacts/transporters`).then()
}
