import ContactList from "components/Contact/ContactList";

export default function Contacts() {

    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title fixed">Contacts</h2>
                <ContactList />
            </section>
        </>
    );
}