import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BsFillArrowDownLeftSquareFill, BsFillArrowUpRightSquareFill } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PaymentService } from 'services';
import './PaymentList.scss';

export default function PaymentList() {

    const [totalPayments, setTotalPayments] = useState(0);
    const [payments, setPayments] = useState([]);

    const getPayments = () => {
        let postData = { offset: payments.length };
        PaymentService.list(postData).then(res => {
            setPayments(payments.concat(res.data.records));
            setTotalPayments(res.data.total_records);
        });
    }


    useEffect(() => {
        getPayments();
    }, [])

    return (
        <>
            {/* Page content start */}

            <Container
                className='payments-container fixed-header-body'
                id="scrollable-div"
                fluid
            >
                {/*Put the scroll bar always on the bottom*/}
                <InfiniteScroll
                    dataLength={payments.length}
                    next={getPayments}
                    hasMore={totalPayments > payments.length}
                    scrollableTarget="scrollable-div"
                    loader={<h4 className='text-center'>Loading...</h4>}
                >
                    <div className='payments-list'>
                        {payments.map((payment, index) => {

                            return <Fragment key={index.toString()}>
                                {(index === 0 || (index > 0 && payments[index]?.date != payments[index - 1]?.date)) && (
                                    <Row>
                                        <div className='payment-date'>
                                            {payments[index]?.date}
                                        </div>
                                    </Row>
                                )
                                }

                                <Row key={index.toString()} className="payment">
                                    <Col xs={2} className="payment-icon">
                                        {payment.type === "0" ? <BsFillArrowUpRightSquareFill size={40} color="red" /> : <BsFillArrowDownLeftSquareFill size={40} color="#00A86B" />}

                                    </Col>
                                    <Col xs={6}>
                                        <div className='payment-type d-flex flex-column'>
                                            <div className="payment-category">
                                                {payment.payment_category_name}
                                            </div>
                                            <div className="payment-description">{payment.description}</div>
                                        </div>

                                    </Col>
                                    <Col xs={4}>
                                        <div className='payment-detail d-flex flex-column text-end'>
                                            <div className={`payment-amount ${payment.type === "0" ? "debit" : "credit"}`}>
                                                {payment.type == "0" ? "-" : "+"}  ₹{payment.amount && payment.amount.toLocaleString('en-IN')}
                                            </div>
                                            <div className='payment-time'>
                                                {payment.time}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Fragment>

                        })}

                    </div>
                </InfiniteScroll>
            </Container>
        </>
    );
}