import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { ContactService, RouteService, VehicleService } from 'services';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";
export default function AddVehicleRoute() {

    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);
    const [vehicles, setVehicles] = useState([]);


    //get vehicles
    const getVehicles = () => {
        VehicleService.list().then(res => {
            setVehicles(res.data);
        });
    }

    useEffect(() => {
        getVehicles()
    }, []);

    const schema = yup.object().shape({
        vehicle_id: yup.string().required("Select vehicle"),
        start_location: yup.string().required("Enter Start Location"),
        start_date: yup.string().required("Select Start Date"),
        start_time: yup.string().required("Select Start Time"),
        opening_fuel: yup.string(),
        opening_def: yup.number(),
        opening_fastag: yup.number(),
        opening_cash: yup.number(),

    });
    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title">Add Route</h2>
                <Container>
                    <Row>
                        <div className="form-box">
                            <div className="form-body">
                                <Formik
                                    validationSchema={schema}
                                    onSubmit={
                                        (values) => {
                                            setSubmitting(true);
                                            RouteService.add(values).then(res => {
                                                setSubmitting(false);
                                                let response = res.data;
                                                if (response.success === true) {
                                                    navigate('/routes')
                                                }
                                                /* Alert section start*/
                                                confirmAlert({
                                                    title: (response.success === true) ? "Success" : "Failed",
                                                    message: response.message,
                                                    buttons: [
                                                        {
                                                            label: 'Ok',
                                                            onClick: () => { }
                                                        }
                                                    ]
                                                });
                                                /* Alert section end*/
                                            });
                                        }
                                    }
                                    initialValues={{
                                        vehicle_id: '',
                                        start_location: '',
                                        start_date: '',
                                        start_time: '',
                                        opening_fuel: '',
                                        opening_def: '',
                                        opening_fastag: '',
                                        opening_cash: '',
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        touched,
                                        isValid,
                                        errors,
                                    }) => (

                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Form.Group as={Col} md="12" controlId="vehicle_id">
                                                <Form.Label>Select Vehicle</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="vehicle_id"
                                                    value={values.vehicle_id}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.vehicle_id}
                                                    isValid={touched.vehicle_id && !errors.vehicle_id}
                                                >
                                                    <option>Select Vehicle</option>
                                                    {
                                                        vehicles.map(vehicle =>
                                                        (<option value={vehicle.id}>
                                                            {vehicle.registration_number}
                                                        </option>)
                                                        )
                                                    }

                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.vehicle_id}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" controlId="start_location">
                                                <Form.Label>Start Location</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Start Location"
                                                    name="start_location"
                                                    value={values.start_location}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.start_location}
                                                    isValid={touched.start_location && !errors.start_location}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.start_location}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Row>
                                                <Form.Group as={Col} xs="6" controlId="start_date">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Start Date"
                                                        name="start_date"
                                                        value={values.start_date}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.start_date}
                                                        isValid={touched.start_date && !errors.start_date}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.start_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} xs="6" controlId="start_time">
                                                    <Form.Label>Start Time</Form.Label>
                                                    <Form.Control
                                                        type="time"
                                                        placeholder="Start Time"
                                                        name="start_time"
                                                        value={values.start_time}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.start_time}
                                                        isValid={touched.time && !errors.start_time}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.start_time}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <hr />

                                            <Row>
                                                <Form.Label>Opening Assets</Form.Label>

                                                <Form.Group as={Col} xs="6" controlId="opening_fuel">
                                                    <Form.Label>Fuel</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Fuel"
                                                        name="opening_fuel"
                                                        value={values.opening_fuel}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.opening_fuel}
                                                        isValid={touched.to && !errors.opening_fuel}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.opening_fuel}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} xs="6" controlId="opening_def">
                                                    <Form.Label>Def</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Def"
                                                        name="opening_def"
                                                        value={values.opening_def}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.opening_def}
                                                        isValid={touched.to && !errors.opening_def}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.opening_def}
                                                    </Form.Control.Feedback>
                                                </Form.Group>


                                                <Form.Group as={Col} xs="6" controlId="opening_fastag">
                                                    <Form.Label>Fastag</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Fastag"
                                                        name="opening_fastag"
                                                        value={values.opening_fastag}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.opening_fastag}
                                                        isValid={touched.to && !errors.opening_fastag}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.opening_fastag}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} xs="6" controlId="opening_cash">
                                                    <Form.Label>Cash</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Cash"
                                                        name="opening_cash"
                                                        value={values.opening_cash}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.opening_cash}
                                                        isValid={touched.to && !errors.opening_cash}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.opening_cash}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>


                                            {/* <DateTimeField id="datepicker" value={values.payment_at} onChange={handleDateTimeChange} /> */}
                                            <Form.Group as={Col} md="12" controlId="submit">
                                                <Button className="btn btn-dark btn-form-submit w-100 mt-3" type="submit" disabled={submitting}>Save</Button>
                                            </Form.Group>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Row>
                </Container >
            </section>
        </>
    );
}