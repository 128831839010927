import axios from 'axios'

export const PaymentCategoryService = {
  list
}

function list () {
  return axios.get(`payment-categories/list`).then()
}

