import React, { useState, useEffect } from 'react';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { BsFillTelephoneFill, BsPencilSquare, BsPersonFill } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { EmployeeService } from 'services';
import './EmployeeList.scss';

export default function EmployeeList() {

    const [totalEmployees, setTotalEmployees] = useState(0);
    const [employees, setEmployees] = useState([]);

    const getEmployees = () => {
        let postData = { offset: employees.length };
        EmployeeService.list(postData).then(res => {
            setEmployees(employees.concat(res.data.records));
            setTotalEmployees(res.data.total_records);
        });
    }


    useEffect(() => {
        getEmployees();
    }, [])

    return (
        <>
            {/* Page content start */}

            <Container
                className='employees-container fixed-header-body'
                id="scrollable-div"
                fluid
            >
                {/*Put the scroll bar always on the bottom*/}
                <InfiniteScroll
                    dataLength={employees.length}
                    next={getEmployees}
                    hasMore={totalEmployees > employees.length}
                    scrollableTarget="scrollable-div"
                    loader={<h4 className='text-center'>Loading...</h4>}
                >
                    <div className='employees-list'>
                        {employees.map((employee, index) => {

                            return <>
                                <Row key={index.toString()} className="employee">
                                    <Col xs={2} className="employee-icon">
                                        {<BsPersonFill size={40} color="green" />}

                                    </Col>
                                    <Col xs={7}>
                                        <div className='employee-type d-flex flex-column'>
                                            <div className="employee-name">
                                                {employee.full_name}
                                            </div>
                                            <div className="employee-address">{employee.address}</div>
                                            <div className="employee-mobile">
                                                <BsFillTelephoneFill size="10" /> {' '}
                                                {employee.mobile}
                                                {employee.mobile2 && `, ${employee.mobile2}`}
                                            </div>
                                        </div>

                                    </Col>
                                    <Col xs={3}>
                                        <div className='employee-detail d-flex flex-column text-end'>
                                            <div className='employee-status'>
                                                <Badge bg={employee.status === "1" ? "success" : "secondary"}> {employee.status === "1" ? "Active" : "Inactive"}</Badge>{' '}
                                                <Link to={`/edit-employee/${employee.id}`}><BsPencilSquare /></Link>
                                            </div>
                                            <div className={`employee-salary`}>
                                                ₹{employee.salary.toLocaleString('en-IN')}
                                            </div>

                                            <div className='employee-salary-history'>
                                                <Link to={`/employee-salaries/${employee.id}`} > <Button variant="outline-primary">Salary</Button></Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>

                        })}

                    </div>
                </InfiniteScroll>
            </Container>
        </>
    );
}