import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsTruck, BsFillArrowUpRightSquareFill, BsFillArrowDownLeftSquareFill, BsFillWalletFill, BsPersonFill, BsFillGridFill, BsFillFilterCircleFill } from "react-icons/bs";
import { DashboardService } from 'services';
import './Dashboard.scss';

export default function Dashboard() {

    const [dashboardData, setDashboardData] = useState({ total_vehicles: 0, total_employees: 0, total_debits: 0, total_credits: 0, total_profit: 0, total_vehicle_making_charges: 0, total_profit_without_vehicle_making_charges: 0 })
    //get bank cards
    const getDashboardData = () => {
        DashboardService.calculation().then(res => {
            setDashboardData(res.data);
        });
    }

    useEffect(() => {
        getDashboardData();
    }, []);
    return (
        <>
            <Container className="dashboard-container without-header-page-body">
                <Row>
                    <Col xs={12} className="dashboard-detail bg-primary">
                        <Row>
                            <Col xs={4} className="dashboard-icon">
                                <BsTruck size={50} color="#FCFCFC" />
                            </Col>
                            <Col xs={8}>
                                <div className='d-flex flex-column'>
                                    <div className="dashboard-label">
                                        Total Vehicle
                                    </div>
                                    <div className="dashboard-value">{dashboardData.total_vehicles}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} className="dashboard-detail bg-info">
                        <Row>
                            <Col xs={4} className="dashboard-icon">
                                <BsPersonFill size={50} color="#FCFCFC" />
                            </Col>
                            <Col xs={8}>
                                <div className='d-flex flex-column'>
                                    <div className="dashboard-label">
                                        Total Employees
                                    </div>
                                    <div className="dashboard-value">{dashboardData.total_employees}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>


                    <Col xs={12} className="dashboard-detail bg-danger">
                        <Row>
                            <Col xs={4} className="dashboard-icon">
                                <BsFillArrowUpRightSquareFill size={50} color="#FCFCFC" />
                            </Col>
                            <Col xs={8}>
                                <div className='d-flex flex-column'>
                                    <div className="dashboard-label">
                                        Total Debits
                                    </div>
                                    <div className="dashboard-value">₹{dashboardData.total_debits.toLocaleString('en-IN')}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} className="dashboard-detail bg-green">
                        <Row>
                            <Col xs={4} className="dashboard-icon">
                                <BsFillArrowDownLeftSquareFill size={50} color="#FCFCFC" />
                            </Col>
                            <Col xs={8}>
                                <div className='d-flex flex-column'>
                                    <div className="dashboard-label">
                                        Total Credits
                                    </div>
                                    <div className="dashboard-value">₹{dashboardData.total_credits.toLocaleString('en-IN')}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} className="dashboard-detail bg-violet">
                        <Row>
                            <Col xs={4} className="dashboard-icon">
                                <BsFillWalletFill size={50} color="#FCFCFC" />
                            </Col>
                            <Col xs={8}>
                                <div className='d-flex flex-column'>
                                    <div className="dashboard-label">
                                        Total Profit
                                    </div>
                                    <div className="dashboard-value">₹{dashboardData.total_profit.toLocaleString('en-IN')}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="dashboard-detail bg-secondary">
                        <Row>
                            <Col xs={4} className="dashboard-icon">
                                <BsFillFilterCircleFill size={50} color="#FCFCFC" />
                            </Col>
                            <Col xs={8}>
                                <div className='d-flex flex-column'>
                                    <div className="dashboard-label">
                                        Making Charges
                                    </div>
                                    <div className="dashboard-value">₹{dashboardData.total_vehicle_making_charges.toLocaleString('en-IN')}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="dashboard-detail bg-success">
                        <Row>
                            <Col xs={4} className="dashboard-icon">
                                <BsFillGridFill size={50} color="#FCFCFC" />
                            </Col>
                            <Col xs={8}>
                                <div className='d-flex flex-column'>
                                    <div className="dashboard-label">
                                        Final Profit
                                    </div>
                                    <div className="dashboard-value">₹{dashboardData.total_profit_without_vehicle_making_charges.toLocaleString('en-IN')}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>

        </>
    )
}