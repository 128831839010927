import VehicleRouteDetail from "components/VehicleRoute/VehicleRouteDetail";

export default function VehicleRoute() {

    return (
        <>
            <section className="without-header-page-body">
                {/* <h2 className="page-title">Route Detail</h2> */}
                <VehicleRouteDetail/>
            </section>
        </>
    );
}