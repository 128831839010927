import EmployeeList from "components/Employee/EmployeeList";

export default function Employees() {

    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title fixed">Employees</h2>
                <EmployeeList />
            </section>
        </>
    );
}