export function formatDateTime (dateTime) {
  if (!dateTime) {
    return ''
  }
  dateTime = new Date(dateTime)

  const year = dateTime.getFullYear()

  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0')
  const day = dateTime
    .getDate()
    .toString()
    .padStart(2, '0')

  const hours = dateTime
    .getHours()
    .toString()
    .padStart(2, '0')

  const minutes = dateTime
    .getMinutes()
    .toString()
    .padStart(2, '0')

  const seconds = dateTime
    .getSeconds()
    .toString()
    .padStart(2, '0')

  return `${day}/${month}/${year} ${hours}:${minutes}`
}
