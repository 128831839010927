import React, { useState, useEffect } from 'react';
import { Formik, useFormik } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { PaymentService, PaymentCategoryService, VehicleService, EmployeeService, RouteService } from 'services';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";

export default function AddPayment() {

    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [paymentCategories, setPaymentCategories] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [routeLoads, setRouteLoads] = useState([]);

    //get vehicles
    const getVehicles = () => {
        VehicleService.list().then(res => {
            setVehicles(res.data);
        });
    }

    //get payment categories
    const getPaymentCategories = () => {
        PaymentCategoryService.list().then(res => {
            setPaymentCategories(res.data);
        });
    }

    //get employees
    const getEmployees = () => {
        EmployeeService.activeEmployees().then(res => {
            setEmployees(res.data);
        });
    }

    //get routes
    const getRoutes = () => {
        RouteService.allRoutes().then(res => {
            setRoutes(res.data);
        });
    }

    //get routes
    const getRouteLoads = (routeId) => {
        RouteService.allRouteLoads(routeId).then(res => {
            setRouteLoads(res.data);
        });
    }


    useEffect(() => {
        getVehicles()
        getPaymentCategories()
        getEmployees()
        getRoutes()
    }, []);


    const schema = yup.object().shape({
        payment_category_id: yup.string().required("Select payment category"),
        vehicle_id: yup.string().required("Select vehicle"),
        amount: yup.number().required("Enter amount").min(1, "Amount should at least 1"),
        type: yup.string().required("Select Type"),
        isNormalPayment: yup.boolean(),
        isSalaryPayment: yup.boolean(),
        isRoutePayment: yup.boolean(),
        date: yup.string().required("Select Date"),
        time: yup.string().required("Select Time"),
        description: yup.string().required("Enter Description"),
    });

    const formik = useFormik({
        validationSchema: schema,
        onSubmit:
            (values) => {
                setSubmitting(true);
                PaymentService.add(values).then(res => {
                    setSubmitting(false);
                    let response = res.data;
                    if (response.success === true) {
                        navigate('/payments')
                    }
                    /* Alert section start*/
                    confirmAlert({
                        title: (response.success === true) ? "Success" : "Failed",
                        message: response.message,
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => { }
                            }
                        ]
                    });
                    /* Alert section end*/
                });
            },
        initialValues: {
            payment_category_id: '',
            vehicle_id: '',
            amount: '',
            isNormalPayment: true,
            isSalaryPayment: false,
            isRoutePayment: false,
            employee_id: '',
            route_id: '',
            route_load_id: '',
            type: '0',
            date: '',
            time: '',
            description: '',
        }
    })

    const handleRouteChange = (event) => {
        formik.setFieldValue('route_id', event.target.value);
        getRouteLoads(event.target.value)
    }

    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title">Add Payment</h2>
                <Container>
                    <Row>
                        <div className="form-box">
                            <div className="form-body">
                                <Form noValidate onSubmit={formik.handleSubmit}>
                                    <Form.Group as={Col} md="12" controlId="vehicle_id">
                                        <Form.Label>Select Vehicle</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="vehicle_id"
                                            value={formik.values.vehicle_id}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik.errors.vehicle_id}
                                            isValid={formik.touched.vehicle_id && !formik.errors.vehicle_id}
                                        >
                                            <option>Select Vehicle</option>
                                            {
                                                vehicles.map(vehicle =>
                                                (<option value={vehicle.id}>
                                                    {vehicle.registration_number}
                                                </option>)
                                                )
                                            }

                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.payment_category_id}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="payment_category_id">
                                        <Form.Label>Payment Category</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="payment_category_id"
                                            value={formik.values.payment_category_id}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik.errors.payment_category_id}
                                            isValid={formik.touched.payment_category_id && !formik.errors.payment_category_id}
                                        >
                                            <option>Select Payment Category</option>
                                            {
                                                paymentCategories.map(paymentCategory =>
                                                (<option value={paymentCategory.id}>
                                                    {paymentCategory.name}
                                                </option>)
                                                )
                                            }

                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.payment_category_id}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="type">
                                        <Form.Label>Payment Type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="type"
                                            value={formik.values.type}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik.errors.type}
                                            isValid={formik.touched.type && !formik.errors.type}
                                        >
                                            <option value="0">Debit</option>
                                            <option value="1">Credit</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.type}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="amount">
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Amount"
                                            name="amount"
                                            value={formik.values.amount}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik.errors.amount}
                                            isValid={formik.touched.amount && !formik.errors.amount}
                                            autoComplete="off"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.amount}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Row>
                                        <Form.Label>Include</Form.Label>
                                        <Form.Group as={Col} xs="4">
                                            <Form.Check
                                                name="isNormalPayment"
                                                type="checkbox"
                                                label="Normal"
                                                value={formik.values.isNormalPayment}
                                                onChange={formik.handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} xs="4">
                                            <Form.Check
                                                name="isSalaryPayment"
                                                type="checkbox"
                                                label="Salary"
                                                value={formik.values.isSalaryPayment}
                                                onChange={formik.handleChange}
                                                disabled={formik.values.isRoutePayment}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} xs="4">
                                            <Form.Check
                                                name="isRoutePayment"
                                                type="checkbox"
                                                label="Route"
                                                value={formik.values.isRoutePayment}
                                                onChange={formik.handleChange}
                                                disabled={formik.values.isSalaryPayment}
                                            />
                                        </Form.Group>
                                    </Row>

                                    {formik.values.isSalaryPayment && <Form.Group as={Col} md="12" controlId="employee_id">
                                        <Form.Label>Select Employee</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="employee_id"
                                            value={formik.values.employee_id}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik.errors.employee_id}
                                            isValid={formik.touched.employee_id && !formik.errors.employee_id}
                                        >
                                            <option>Select Employee</option>
                                            {
                                                employees.map(employee =>
                                                (<option value={employee.id}>
                                                    {employee.full_name}
                                                </option>)
                                                )
                                            }

                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.employee_id}
                                        </Form.Control.Feedback>
                                    </Form.Group>}

                                    {formik.values.isRoutePayment && <Form.Group as={Col} md="12" controlId="route_id">
                                        <Form.Label>Select Route</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="route_id"
                                            value={formik.values.route_id}
                                            onChange={handleRouteChange}
                                            isInvalid={!!formik.errors.route_id}
                                            isValid={formik.touched.route_id && !formik.errors.route_id}
                                        >
                                            <option>Select Route</option>
                                            {
                                                routes.map(route =>
                                                (<option value={route.id}>
                                                    {route.start_location} - {route.end_location} ({route.start_date})
                                                </option>)
                                                )
                                            }

                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.route_id}
                                        </Form.Control.Feedback>
                                    </Form.Group>}

                                    {formik.values.route_id && <Form.Group as={Col} md="12" controlId="route_load_id">
                                        <Form.Label>Select Route Load</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="route_load_id"
                                            value={formik.values.route_load_id}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik.errors.route_load_id}
                                            isValid={formik.touched.route_load_id && !formik.errors.route_load_id}
                                        >
                                            <option>Select Route Load</option>
                                            {
                                                routeLoads.map(routeLoad =>
                                                (<option value={routeLoad.id}>
                                                    {routeLoad.start_location} - {routeLoad.end_location}
                                                </option>)
                                                )
                                            }

                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.route_load_id}
                                        </Form.Control.Feedback>
                                    </Form.Group>}

                                    <Row>
                                        <Form.Group as={Col} xs="6" controlId="date">
                                            <Form.Label>Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                placeholder="Date"
                                                name="date"
                                                value={formik.values.date}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.date}
                                                isValid={formik.touched.date && !formik.errors.date}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.date}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="6" controlId="time">
                                            <Form.Label>Time</Form.Label>
                                            <Form.Control
                                                type="time"
                                                placeholder="Time"
                                                name="time"
                                                value={formik.values.time}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.time}
                                                isValid={formik.touched.time && !formik.errors.time}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.time}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Form.Group as={Col} md="12" controlId="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={2}
                                            placeholder="Description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik.errors.description}
                                            isValid={formik.touched.description && !formik.errors.description}
                                            autoComplete="off"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {/* <DateTimeField id="datepicker" value={formik.values.payment_at} onChange={handleDateTimeChange} /> */}
                                    <Form.Group as={Col} md="12" controlId="submit">
                                        <Button className="btn btn-dark btn-form-submit w-100 mt-3" type="submit" disabled={submitting}>Save</Button>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </Row>
                </Container >
            </section>
        </>
    );
}