import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { EmployeeSalaryService, EmployeeService } from 'services';
import * as yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";

export default function SalaryGenerate() {

    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);
    const [employees, setEmployees] = useState([]);
    //get employees
    const getEmployees = () => {
        EmployeeService.activeEmployees().then(res => {
            setEmployees(res.data);
        });
    }

    useEffect(() => {
        getEmployees();
    }, []);

    const schema = yup.object().shape({
        employee_id: yup.string().required("Select employee"),
        monthly_salary: yup.number().required("Enter amount").min(1, "Amount should at least 1"),
        month: yup.string().required("Enter month"),
        total_days: yup.number().required("Total Days"),
        present_days: yup.number().required("Present Days"),
        absent_days: yup.number().required("Absent Days"),
        final_salary: yup.number().required("Final Salary"),
        description: yup.string().required("Enter Description"),
    });

    return (
        <>
            <Container>
                <Row>
                    <div className="form-box">
                        <div className="form-body">
                            <Formik
                                validationSchema={schema}
                                onSubmit={
                                    (values) => {
                                        console.log("values", values)
                                        setSubmitting(true);
                                        EmployeeSalaryService.salaryGenerate(values).then(res => {
                                            setSubmitting(false);
                                            let response = res.data;
                                            if (response.success === true) {
                                                navigate(`/employee-salaries/${values.employee_id}`)
                                            }
                                            /* Alert section start*/
                                            confirmAlert({
                                                title: (response.success === true) ? "Success" : "Failed",
                                                message: response.message,
                                                buttons: [
                                                    {
                                                        label: 'Ok',
                                                        onClick: () => { }
                                                    }
                                                ]
                                            });
                                            /* Alert section end*/
                                        });
                                    }
                                }
                                initialValues={{
                                    employee_id: '',
                                    monthly_salary: '',
                                    month: '',
                                    total_days: '',
                                    present_days: '',
                                    absent_days: '',
                                    final_salary: '',
                                    description: '',
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (

                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group as={Col} md="12" controlId="employee_id">
                                            <Form.Label>Select Employee</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="employee_id"
                                                value={values.employee_id}
                                                onChange={handleChange}
                                                isInvalid={!!errors.employee_id}
                                                isValid={touched.employee_id && !errors.employee_id}
                                            >
                                                <option>Select Employee</option>
                                                {
                                                    employees.map(employee =>
                                                    (<option value={employee.id}>
                                                        {employee.full_name}
                                                    </option>)
                                                    )
                                                }

                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.employee_id}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="monthly_salary">
                                            <Form.Label>Monthly Salary</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Monthly Salary"
                                                name="monthly_salary"
                                                value={values.monthly_salary}
                                                onChange={handleChange}
                                                isInvalid={!!errors.monthly_salary}
                                                isValid={touched.monthly_salary && !errors.monthly_salary}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.monthly_salary}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="month">
                                            <Form.Label>Month</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Month"
                                                name="month"
                                                value={values.month}
                                                onChange={handleChange}
                                                isInvalid={!!errors.month}
                                                isValid={touched.month && !errors.month}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.month}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="total_days">
                                            <Form.Label>Total Days</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Total Days"
                                                name="total_days"
                                                value={values.total_days}
                                                onChange={handleChange}
                                                isInvalid={!!errors.total_days}
                                                isValid={touched.total_days && !errors.total_days}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.total_days}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="present_days">
                                            <Form.Label>Present Days</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Present Days"
                                                name="present_days"
                                                value={values.present_days}
                                                onChange={handleChange}
                                                isInvalid={!!errors.present_days}
                                                isValid={touched.present_days && !errors.present_days}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.present_days}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="absent_days">
                                            <Form.Label>Absent Days</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Absent Days"
                                                name="absent_days"
                                                value={values.absent_days}
                                                onChange={handleChange}
                                                isInvalid={!!errors.absent_days}
                                                isValid={touched.absent_days && !errors.absent_days}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.absent_days}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="final_salary">
                                            <Form.Label>Final Salary</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Final Salary"
                                                name="final_salary"
                                                value={values.final_salary}
                                                onChange={handleChange}
                                                isInvalid={!!errors.final_salary}
                                                isValid={touched.final_salary && !errors.final_salary}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.final_salary}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                placeholder="Description"
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                isInvalid={!!errors.description}
                                                isValid={touched.description && !errors.description}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="submit">
                                            <Button className="btn btn-dark btn-form-submit w-100 mt-3" type="submit" disabled={submitting}>Save</Button>
                                        </Form.Group>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </Row>
            </Container >
        </>
    );
}