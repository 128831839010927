import React, { useState, useEffect } from 'react';
import { Badge, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { BsFillArrowUpRightSquareFill } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { EmployeePaymentService, EmployeeSalaryService } from 'services';
import './SalaryList.scss';

export default function SalaryList() {
    const params = useParams()
    const employeeId = params.id;

    //employee-salaries
    const [totalSalaries, setTotalSalaries] = useState(0);
    const [employeeSalaries, setSalaries] = useState([]);

    //paid employee-salaries
    const [totalPaidSalaries, setTotalPaidSalaries] = useState(0);
    const [employeePayments, setPaidSalaries] = useState([]);
    const [salaryCalculation, setSalaryCalculation] = useState({
        total_paid_salary: 0,
        total_paid_bonus: 0,
        total_salary: 0
    });

    const getSalaries = () => {
        let postData = { offset: employeeSalaries.length, employeeId };
        EmployeeSalaryService.salaryList(postData).then(res => {
            setSalaries(employeeSalaries.concat(res.data.records));
            setTotalSalaries(res.data.total_records);
            //empty paid salary
            setPaidSalaries([]);
        });
    }

    const getPaidSalaries = () => {
        let postData = { offset: employeePayments.length, employeeId };
        EmployeePaymentService.employeePaymentList(postData).then(res => {
            setPaidSalaries(employeePayments.concat(res.data.records));
            setTotalPaidSalaries(res.data.total_records);
            //empty salary
            setSalaries([]);
        });
    }

    const getSalaryCalculation = () => {
        let postData = { employeeId };
        EmployeeSalaryService.salaryCalculation(postData).then(res => {
            console.log("res.data", res.data)
            setSalaryCalculation(res.data);
        });
    }


    useEffect(() => {
        getPaidSalaries();
        getSalaryCalculation();
    }, [])


    const handleTabSelect = (eventKey) => {
        if (eventKey === "paid-employee-salaries") {
            getPaidSalaries()
        } else {
            getSalaries();
        }
    }

    return (
        <>
            {/* Page content start */}

            <Container
                className='salary-container fixed-header-body'
                fluid
            >
                <div className='salary-analysis'>
                    <Row>
                        <Col xs={4}>
                            <div className='salary-analysis-tile bg-violet d-flex flex-column'>
                                <div className="salary-analysis-label">
                                    Total Salary
                                </div>
                                <div className="salary-analysis-value">₹{salaryCalculation.total_salary.toLocaleString('en-IN')}</div>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='salary-analysis-tile bg-green d-flex flex-column'>
                                <div className="salary-analysis-label">
                                    Paid Salary
                                </div>
                                <div className="salary-analysis-value">₹{salaryCalculation.total_paid_salary.toLocaleString('en-IN')}</div>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='salary-analysis-tile bg-success d-flex flex-column'>
                                <div className="salary-analysis-label">
                                    Paid Bonus
                                </div>
                                <div className="salary-analysis-value">₹{salaryCalculation.total_paid_bonus.toLocaleString('en-IN')}</div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Tabs
                    defaultActiveKey="paid-employee-salaries"
                    id="scrollable-salary-div"
                    className="mb-3"
                    onSelect={(eventKey) => handleTabSelect(eventKey)}
                    justify

                >
                    <Tab eventKey="paid-employee-salaries" title="Paid Salary">
                        {employeePayments.length > 0 ? <div id="scrollable-paid-salary-div">
                            <InfiniteScroll
                                dataLength={employeePayments.length}
                                next={getPaidSalaries}
                                hasMore={totalPaidSalaries > employeePayments.length}
                                scrollableTarget="scrollable-paid-salary-div"
                                loader={<h4 className='text-center'>Loading...</h4>}
                            >
                                <div className='employee-salaries-list'>
                                    {employeePayments.map((paidSalary, index) => {
                                        return <>
                                            <Row key={index.toString()} className="salary">
                                                <Col xs={2} className="salary-icon">
                                                    <BsFillArrowUpRightSquareFill size={40} color="00A86B" />
                                                </Col>

                                                <Col xs={7}>
                                                    <div className='salary-type d-flex flex-column'>
                                                        <div className="salary-month">
                                                            {paidSalary.payment_date}
                                                        </div>
                                                        <div className="salary-description">{paidSalary.description}</div>
                                                    </div>

                                                </Col>
                                                <Col xs={3}>
                                                    <div className='salary-detail d-flex flex-column text-end'>
                                                        <div className='salary-final'>
                                                            ₹{paidSalary.amount && paidSalary.amount.toLocaleString('en-IN')}
                                                        </div>
                                                        <div className='salary-type'>
                                                            <Badge bg={paidSalary.type == "0" ? "success" : "primary"}>{paidSalary.type == "0" ? "Salary" : "Bonus"}</Badge>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    })}
                                </div>
                            </InfiniteScroll>
                        </div> : <div className='no-record-found'>No Record Found</div>}

                    </Tab>
                    <Tab eventKey="employee-salaries" title="Salary">
                        {employeeSalaries.length > 0 ? <div id="scrollable-salary-div">
                            <InfiniteScroll
                                dataLength={employeeSalaries.length}
                                next={getSalaries}
                                hasMore={totalSalaries > employeeSalaries.length}
                                scrollableTarget="salary-div"
                                loader={<h4 className='text-center'>Loading...</h4>}
                            >
                                <div className='employee-salaries-list'>
                                    {employeeSalaries.map((salary, index) => {
                                        return <>
                                            <Row key={index.toString()} className="salary">

                                                <Col xs={6}>
                                                    <div className='salary-type d-flex flex-column'>
                                                        <div className="salary-month">
                                                            {salary.month}
                                                        </div>
                                                        <div className="salary-description">{salary.description}</div>
                                                        <div className="salary-days">
                                                            <b>Present:</b> {salary.present_days} {' '}
                                                            <b>Absent:</b> {salary.absent_days}
                                                        </div>
                                                    </div>

                                                </Col>
                                                <Col xs={6}>
                                                    <div className='salary-detail d-flex flex-column text-end'>
                                                        <div className='salary-final'>
                                                            <b>Final: </b>₹{salary.final_salary && salary.final_salary.toLocaleString('en-IN')}
                                                        </div>
                                                        <div className='salary-monthly'>
                                                            <b>Monthly: </b>₹{salary.monthly_salary && salary.monthly_salary.toLocaleString('en-IN')}
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </>

                                    })}

                                </div>
                            </InfiniteScroll>
                        </div> : <div className='no-record-found'>No Record Found</div>}


                    </Tab>
                </Tabs>
            </Container >
        </>
    );
}