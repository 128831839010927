import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate, useParams } from "react-router-dom";
import { EmployeeService } from 'services';
import * as yup from 'yup';


export default function AddEditEmployee() {

    const params = useParams();
    const employeeId = params?.id;
    const [employee, setEmployee] = useState({});

    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);
    const schema = yup.object().shape({
        id: yup.number(),
        full_name: yup.string().required("Enter Full Name"),
        mobile: yup.string().required("Enter Mobile Number"),
        mobile2: yup.string(),
        address: yup.string().required("Enter Address"),
        join_date: yup.string().required("Enter Join Date"),
        salary: yup.string().required("Enter Salary"),
        status: yup.string().required("Select Status"),
    });


    //get employee
    const getEmployee = (employeeId) => {
        EmployeeService.detail(employeeId).then(res => {
            setEmployee(res.data);
        });
    }

    useEffect(() => {
        employeeId && getEmployee(employeeId);
    }, [employeeId]);

    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title">{employeeId ? 'Edit' : 'Add'} Employee</h2>
                <Container>
                    <Row>
                        <div className="form-box">
                            <div className="form-body">
                                <Formik
                                    enableReinitialize
                                    validationSchema={schema}
                                    onSubmit={
                                        (values) => {
                                            setSubmitting(true);
                                            EmployeeService.addEdit(values).then(res => {
                                                setSubmitting(false);
                                                let response = res.data;
                                                if (response.success === true) {
                                                    navigate('/employees')
                                                }
                                                /* Alert section start*/
                                                confirmAlert({
                                                    title: (response.success === true) ? "Success" : "Failed",
                                                    message: response.message,
                                                    buttons: [
                                                        {
                                                            label: 'Ok',
                                                            onClick: () => { }
                                                        }
                                                    ]
                                                });
                                                /* Alert section end*/
                                            });
                                        }
                                    }
                                    initialValues={{
                                        id: employee?.id || null,
                                        full_name: employee?.full_name,
                                        mobile: employee?.mobile || "",
                                        mobile2: employee?.mobile2 || "",
                                        address: employee?.address || "",
                                        join_date: employee?.join_date || "",
                                        salary: employee?.salary || "",
                                        status: employee?.status || "0"
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        touched,
                                        isValid,
                                        errors,
                                    }) => (

                                        <Form noValidate onSubmit={handleSubmit}>

                                            <Form.Group as={Col} md="12" controlId="full_name">
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Full Name"
                                                    name="full_name"
                                                    value={values.full_name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.full_name}
                                                    isValid={touched.full_name && !errors.full_name}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.full_name}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" controlId="mobile">
                                                <Form.Label>Mobile</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Mobile"
                                                    name="mobile"
                                                    value={values.mobile}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.mobile}
                                                    isValid={touched.mobile && !errors.mobile}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.mobile}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" controlId="mobile2">
                                                <Form.Label>Mobile 2</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Mobile 2"
                                                    name="mobile2"
                                                    value={values.mobile2}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.mobile2}
                                                    isValid={touched.mobile2 && !errors.mobile2}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.mobile2}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" controlId="address">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Address"
                                                    name="address"
                                                    value={values.address}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.address}
                                                    isValid={touched.address && !errors.address}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" controlId="salary">
                                                <Form.Label>Salary</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Salary"
                                                    name="salary"
                                                    value={values.salary}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.salary}
                                                    isValid={touched.salary && !errors.salary}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.salary}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs="12" controlId="join_date">
                                                <Form.Label>Join Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Join Date"
                                                    name="join_date"
                                                    value={values.join_date}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.join_date}
                                                    isValid={touched.join_date && !errors.join_date}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.join_date}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" controlId="status">
                                                <Form.Label>Select Status</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="status"
                                                    value={values.status}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.status}
                                                    isValid={touched.status && !errors.status}
                                                >
                                                    <option value="1">Active</option>
                                                    <option value="0">In active</option>

                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.status}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="submit">
                                                <Button className="btn btn-dark btn-form-submit w-100 mt-3" type="submit" disabled={submitting}>Save</Button>
                                            </Form.Group>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Row>
                </Container >
            </section>
        </>
    );
}