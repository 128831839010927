import axios from 'axios'

export const EmployeePaymentService = {
  employeePaymentList,
  employeePaymentAdd
}

function employeePaymentList (data) {
  return axios.post(`employee-payments/list`, data).then()
}
function employeePaymentAdd (data) {
  return axios.post(`employee-payments/add`, data).then()
}
