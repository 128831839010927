import React, { Fragment, useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { BsClockHistory, BsFillArrowDownLeftSquareFill, BsFillArrowUpRightSquareFill, BsPencilSquare, BsPeople, BsPerson, BsUpload } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate, useParams } from "react-router-dom";
import { RouteService } from 'services';
import { formatDateTime } from 'utils/commonFunction';
import * as yup from 'yup';
import './VehicleRouteDetail.scss';

export default function VehicleRouteDetail() {

    const params = useParams();
    const navigate = useNavigate();
    const [route, setRoute] = useState([]);

    //route loads
    const [totalLoads, setTotalLoads] = useState(0);
    const [loads, setLoads] = useState([]);
    const [analysis, setAnalysis] = useState({});

    //route payments
    const [totalPayments, setTotalPayments] = useState(0);
    const [payments, setPayments] = useState([]);

    //get vehicle route
    const getRoute = () => {
        RouteService.detail(params.id).then(res => {
            setRoute(res.data);
        });
    }


    const getLoads = () => {
        let postData = { offset: loads.length, routeId: params.id };
        RouteService.loads(postData).then(res => {
            setLoads(loads.concat(res.data.records));
            setTotalLoads(res.data.total_records);
            //empty paid load
            setPayments([]);
        });
    }

    const getPayments = () => {
        let postData = { offset: payments.length, route_id: params.id };
        RouteService.payments(postData).then(res => {
            setPayments(payments.concat(res.data.records));
            setTotalPayments(res.data.total_records);
            //empty load
            setLoads([]);
        });
    }

    const getAnalysis = () => {
        let postData = { route_id: params.id };
        RouteService.analysis(postData).then(res => {
            setAnalysis(res.data)
            setPayments([]);
            setLoads([]);
        });
    }


    const handleTabSelect = (eventKey) => {
        if (eventKey === "route-loads") {
            getLoads();
        } else if (eventKey === "route-payments") {
            getPayments()
        } else {
            getAnalysis();
        }
    }


    useEffect(() => {
        getRoute()
        getLoads();
    }, [])

    return (
        <>
            <Container className="route-container">
                <Row className="route">
                    <Col xs={9}>
                        <div className='route-type d-flex flex-column'>
                            <div className="route-location">
                                {route.start_location} - {route.end_location}
                            </div>

                            <div className="route-time">
                                <BsClockHistory size="10" /> {' '}
                                {formatDateTime(`${route.start_date} ${route.start_time}`)} - {route.end_date && formatDateTime(`${route.end_date} ${route.end_time}`)}
                            </div>
                        </div>
                    </Col>
                    <Col xs={3} className="text-end">
                        <div className='route-detail d-flex flex-column'>
                            <div className='route-link'>
                                <Link to={`/routes/${route?.id}/add-load`}> <Button variant="outline-primary" size="sm">+Load</Button></Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Tabs
                    defaultActiveKey="route-loads"
                    id="scrollable-load-div"
                    className="mb-3"
                    onSelect={(eventKey) => handleTabSelect(eventKey)}
                    justify

                >
                    <Tab eventKey="route-loads" title="Loads">
                        {loads.length > 0 ? <div id="scrollable-load-div">
                            <InfiniteScroll
                                dataLength={loads.length}
                                next={getLoads}
                                hasMore={totalLoads > loads.length}
                                scrollableTarget="load-div"
                                loader={<h4 className='text-center'>Loading...</h4>}
                            >
                                <div className='route-loads'>
                                    {loads.map((load, index) => {
                                        return <>
                                            <Row key={index.toString()} className="load">
                                                <Col xs={8}>
                                                    <div className='d-flex flex-column'>
                                                        <div className="load-location">
                                                            {load.start_location} To {load.end_location}
                                                        </div>

                                                        <div className="load-transporter"> <BsPerson size="10" />{load.full_name}</div>
                                                    </div>

                                                </Col>
                                                <Col xs={4}>
                                                    <div className='d-flex flex-column text-end'>
                                                        <div className='load-weight'>
                                                            <b>W: </b>{load.weight} Tons
                                                        </div>
                                                        <div className='load-rate'>
                                                            <b>R: </b>₹{load.rate && load.rate.toLocaleString('en-IN')}/Ton
                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col xs={10}>
                                                    <div className="load-time">
                                                        <BsClockHistory size="10" /> {' '}
                                                        {formatDateTime(`${load?.start_date} ${load?.start_time}`)} - {load?.end_date && formatDateTime(`${load?.end_date} ${load?.end_time}`)}

                                                    </div>
                                                </Col>
                                                <Col xs={2} className="text-end">
                                                    <Link to={`/routes/${route?.id}/edit-load/${load.id}`}><BsPencilSquare /></Link>
                                                </Col>
                                            </Row>
                                        </>

                                    })}

                                </div>
                            </InfiniteScroll>
                        </div> : <div className='no-record-found'>No Record Found</div>}
                    </Tab>
                    <Tab eventKey="route-payments" title="Payments">
                        <Container
                            className='payments-container'
                            id="scrollable-payment-div"
                            fluid
                        >
                            {payments.length > 0 ?
                                <InfiniteScroll
                                    dataLength={payments.length}
                                    next={getPayments}
                                    hasMore={totalPayments > payments.length}
                                    scrollableTarget="scrollable-payment-div"
                                    loader={<h4 className='text-center'>Loading...</h4>}
                                >
                                    <div className='payments-list'>
                                        {payments.map((payment, index) => {

                                            return <Fragment key={index.toString()}>
                                                {(index === 0 || (index > 0 && payments[index]?.date != payments[index - 1]?.date)) && (
                                                    <Row>
                                                        <div className='payment-date'>
                                                            {payments[index]?.date}
                                                        </div>
                                                    </Row>
                                                )
                                                }

                                                <Row key={index.toString()} className="payment">
                                                    <Col xs={2} className="payment-icon">
                                                        {payment.type === "0" ? <BsFillArrowUpRightSquareFill size={40} color="red" /> : <BsFillArrowDownLeftSquareFill size={40} color="#00A86B" />}

                                                    </Col>
                                                    <Col xs={6}>
                                                        <div className='payment-type d-flex flex-column'>
                                                            <div className="payment-category">
                                                                {payment.payment_category_name}
                                                            </div>
                                                            <div className="payment-description">{payment.description}</div>
                                                        </div>

                                                    </Col>
                                                    <Col xs={4}>
                                                        <div className='payment-detail d-flex flex-column text-end'>
                                                            <div className={`payment-amount ${payment.type === "0" ? "debit" : "credit"}`}>
                                                                {payment.type == "0" ? "-" : "+"}  ₹{payment.amount && payment.amount.toLocaleString('en-IN')}
                                                            </div>
                                                            <div className='payment-time'>
                                                                {payment.time}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Fragment>
                                        })}
                                    </div>
                                </InfiniteScroll>
                                : <div className='no-record-found'>No Record Found</div>}
                        </Container>
                    </Tab>
                    <Tab eventKey="route-analysis" title="Analysis">
                        <Row className="route-analysis">
                            <Col xs={6}>
                                <div className='asset-type'>Opening</div>
                                <div className='asset-name'>Fuel: {route?.opening_fuel}</div>
                                <div className='asset-name'>Def: {route?.opening_def}</div>
                                <div className='asset-name'>Fastag: {route?.opening_fastag}</div>
                                <div className='asset-name'>Cash: {route?.opening_cash}</div>
                            </Col>
                            <Col xs={6}>
                                <div className='asset-type'>Closing</div>
                                <div className='asset-name'>Fuel: {route?.closing_fuel}</div>
                                <div className='asset-name'>Def: {route?.closing_def}</div>
                                <div className='asset-name'>Fastag: {route?.closing_fastag}</div>
                                <div className='asset-name'>Cash: {route?.closing_cash}</div>
                            </Col>
                            <Col xs={4}>
                                <div className='amount text-info'>
                                    <b>Credits:</b><br />
                                    ₹{analysis?.total_credits}
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className='amount text-danger'>
                                    <b>Debits: </b><br />
                                    ₹{analysis.total_debits}
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className='amount text-success'>
                                    <b>Profit: </b><br />
                                    ₹{analysis?.total_profit}
                                </div>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Container >
        </>
    );
}