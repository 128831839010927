import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'hooks/useAuth'
import 'index.scss'
import App from 'App'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import reportWebVitals from 'reportWebVitals'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import axios from 'axios'
axios.defaults.baseURL = process.env.REACT_APP_SERVER_PATH + 'api/'
axios.defaults.headers.post['Content-Type'] = 'application/json'

//add token
axios.interceptors.request.use(request => {
  // add auth header with jwt if user is logged in and request is to the api url
  if (localStorage.getItem('currentUser')) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    if (currentUser) {
      request.headers.Authorization = currentUser.token
    }
  }
  return request
})

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
