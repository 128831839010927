import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { BsClockHistory, BsUpload } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { RouteService } from 'services';
import { formatDateTime } from 'utils/commonFunction';
import './VehicleRouteList.scss';

export default function VehicleRouteList() {

    const [totalRoutes, setTotalRoutes] = useState(0);
    const [routes, setRoutes] = useState([]);

    const getRoutes = () => {
        let postData = { offset: routes.length };
        RouteService.list(postData).then(res => {
            setRoutes(routes.concat(res.data.records));
            setTotalRoutes(res.data.total_records);
        });
    }


    useEffect(() => {
        getRoutes();
    }, [])

    return (
        <>
            {/* Page content start */}

            <Container
                className='routes-container fixed-header-body'
                id="scrollable-div"
                fluid
            >
                {routes.length > 0 ?
                    <InfiniteScroll
                        dataLength={routes.length}
                        next={getRoutes}
                        hasMore={totalRoutes > routes.length}
                        scrollableTarget="scrollable-div"
                        loader={<h4 className='text-center'>Loading...</h4>}
                    >

                        <div className='routes-list'>
                            {routes.map((route, index) => {

                                return <>
                                    <Row key={index.toString()} className="route">
                                        <Col xs={2} className="route-icon">
                                            {<BsUpload size={40} color="green" />}

                                        </Col>
                                        <Col xs={7}>
                                            <div className='route-type d-flex flex-column'>
                                                <div className="route-location">
                                                    {route.start_location} - {route.end_location}
                                                </div>

                                                <div className="route-time">
                                                    <BsClockHistory size="10" /> {' '}
                                                    {formatDateTime(`${route?.start_date} ${route?.start_time}`)}
                                                </div>
                                                <div className="route-time">
                                                    <BsClockHistory size="10" /> {' '}
                                                    {route?.end_date && formatDateTime(`${route?.end_date} ${route?.end_time}`)}
                                                </div>
                                            </div>

                                        </Col>
                                        <Col xs={3}>
                                            <div className='route-detail d-flex flex-column text-end'>
                                                <div className='route-link'>
                                                    <Link to={`/routes/${route.id}`} > <Button variant="outline-primary">View</Button></Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>

                            })}

                        </div>

                    </InfiniteScroll>
                    : <div className='no-record-found'>No Record Found</div>}
            </Container>
        </>
    );
}