import axios from 'axios'

export const PaymentService = {
  list,
  add
}

function list (data) {
  return axios.post(`payments/list`, data).then()
}
function add (data) {
  return axios.post(`payments/add`, data).then()
}
