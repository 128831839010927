import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate, useParams } from "react-router-dom";
import { ContactService, RouteService, VehicleService } from 'services';
import * as yup from 'yup';

export default function AddEditVehicleRouteLoad() {
    const params = useParams();
    const { routeId, loadId } = params;
    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);
    const [load, setLoad] = useState({});
    const [transporters, setTransporters] = useState([]);

    //get transporters
    const getTransporters = () => {
        ContactService.transporters().then(res => {
            setTransporters(res?.data?.records);
        });
    }

    //get route load
    const getRouteLoad = () => {
        RouteService.load(loadId).then(res => {
            setLoad(res?.data);
        });
    }

    useEffect(() => {
        getTransporters()
    }, []);

    useEffect(() => {
        loadId && getRouteLoad(loadId);
    }, [loadId]);

    const schema = yup.object().shape({
        route_id: yup.string().required("Select vehicle"),
        load_id: yup.string(),
        transporter_id: yup.number().required("Select vehicle"),
        start_location: yup.string().required("Enter Start Location"),
        end_location: yup.string().required("Enter End Location"),
        weight: yup.number().required("Enter Weigth"),
        rate: yup.number().required("Enter Rate"),
        start_date: yup.string().required("Select Start Date"),
        start_time: yup.string().required("Select Start Time"),
        end_date: yup.string(),
        end_time: yup.string(),
        description: yup.string(),
    });
    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title">{loadId ? 'Edit' : 'Add'} Load</h2>
                <Container>
                    <Row>
                        <div className="form-box">
                            <div className="form-body">
                                <Formik
                                    enableReinitialize
                                    validationSchema={schema}
                                    onSubmit={
                                        (values) => {
                                            setSubmitting(true);
                                            RouteService.addEditLoad(values).then(res => {
                                                setSubmitting(false);
                                                let response = res.data;
                                                if (response.success === true) {
                                                    navigate(`/routes/${routeId}`)
                                                }
                                                /* Alert section start*/
                                                confirmAlert({
                                                    title: (response.success === true) ? "Success" : "Failed",
                                                    message: response.message,
                                                    buttons: [
                                                        {
                                                            label: 'Ok',
                                                            onClick: () => { }
                                                        }
                                                    ]
                                                });
                                                /* Alert section end*/
                                            });
                                        }
                                    }
                                    initialValues={{
                                        route_id: routeId,
                                        load_id: loadId || "",
                                        transporter_id: load?.transporter_id || "",
                                        start_location: load?.start_location || "",
                                        end_location: load?.end_location || "",
                                        weight: load?.weight || "",
                                        end_location: load?.end_location || "",
                                        rate: load?.rate || "",
                                        start_date: load?.start_date || "",
                                        start_time: load?.start_time || "",
                                        end_date: load?.end_date || "",
                                        end_time: load?.end_time || "",
                                        description: load?.description || "",
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        touched,
                                        isValid,
                                        errors,
                                    }) => (

                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Form.Group as={Col} md="12" controlId="transporter_id">
                                                <Form.Label>Select Transporter</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="transporter_id"
                                                    value={values.transporter_id}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.transporter_id}
                                                    isValid={touched.transporter_id && !errors.transporter_id}
                                                >
                                                    <option>Select Transporter</option>
                                                    {
                                                        transporters.map(transporter =>
                                                        (<option value={transporter.id}>
                                                            {transporter.full_name}
                                                        </option>)
                                                        )
                                                    }

                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.payment_category_id}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="12" controlId="start_location">
                                                <Form.Label>Start Location</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Start Location"
                                                    name="start_location"
                                                    value={values.start_location}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.start_location}
                                                    isValid={touched.start_location && !errors.start_location}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.start_location}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" controlId="end_location">
                                                <Form.Label>End Location</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="End Location"
                                                    name="end_location"
                                                    value={values.end_location}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.end_location}
                                                    isValid={touched.to && !errors.end_location}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.end_location}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Row>
                                                <Form.Group as={Col} xs="6" controlId="weight">
                                                    <Form.Label>Weight(Tons)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Weight"
                                                        name="weight"
                                                        value={values.weight}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.weight}
                                                        isValid={touched.weight && !errors.weight}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.weight}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} xs="6" controlId="rate">
                                                    <Form.Label>Rate(Per Ton)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Rate"
                                                        name="rate"
                                                        value={values.rate}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.rate}
                                                        isValid={touched.rate && !errors.rate}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.rate}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row>
                                                <Form.Group as={Col} xs="6" controlId="start_date">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Start Date"
                                                        name="start_date"
                                                        value={values.start_date}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.start_date}
                                                        isValid={touched.start_date && !errors.start_date}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.start_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} xs="6" controlId="start_time">
                                                    <Form.Label>Start Time</Form.Label>
                                                    <Form.Control
                                                        type="time"
                                                        placeholder="Start Time"
                                                        name="start_time"
                                                        value={values.start_time}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.start_time}
                                                        isValid={touched.time && !errors.start_time}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.start_time}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row>
                                                <Form.Group as={Col} xs="6" controlId="end_date">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="End Date"
                                                        name="end_date"
                                                        value={values.end_date}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.end_date}
                                                        isValid={touched.end_date && !errors.end_date}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.end_date}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} xs="6" controlId="end_time">
                                                    <Form.Label>End Time</Form.Label>
                                                    <Form.Control
                                                        type="time"
                                                        placeholder="End Time"
                                                        name="end_time"
                                                        value={values.end_time}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.end_time}
                                                        isValid={touched.time && !errors.end_time}
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.end_time}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Form.Group as={Col} md="12" controlId="description">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={2}
                                                    placeholder="Description"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.description}
                                                    isValid={touched.description && !errors.description}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.description}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            {/* <DateTimeField id="datepicker" value={values.payment_at} onChange={handleDateTimeChange} /> */}
                                            <Form.Group as={Col} md="12" controlId="submit">
                                                <Button className="btn btn-dark btn-form-submit w-100 mt-3" type="submit" disabled={submitting}>Save</Button>
                                            </Form.Group>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Row>
                </Container >
            </section>
        </>
    );
}