import React from 'react';
import { AuthService } from 'services';
import { Formik } from 'formik';
import { Form, Button, Col, Container, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { useAuth } from "hooks/useAuth";

const schema = yup.object().shape({
    mobile: yup.string().required().min(10, "Enter valid mobile number").max(10, "Enter valid mobile number"),
    password: yup.string().required(),
});

export default function Login() {
    const { login } = useAuth();

    let navigate = useNavigate();

    return (
        <>
            {/* Page content start */}
            <section className="without-header-footer-page-body">
                <Container>
                    <Row>
                        <Col>
                            <div className="form-box">
                                <div className="form-header text-center">
                                    <img src="/assets/images/logo-white.svg" alt="logo" width='180px' />
                                    <h5 className="text-center">Welcome Back</h5>
                                </div>
                                <div className="form-body">
                                    <Formik
                                        validationSchema={schema}
                                        onSubmit={
                                            (values) => {
                                                AuthService.login(values).then(res => {
                                                    let response = res.data;
                                                    if (response.success === true) {
                                                        login(res.data.data)
                                                    }
                                                    /* Alert section start*/
                                                    confirmAlert({
                                                        title: (response.success === true) ? "Success" : "Failed",
                                                        message: response.message,
                                                        buttons: [
                                                            {
                                                                label: 'Ok',
                                                                onClick: () => { }
                                                            }
                                                        ]
                                                    });
                                                    /* Alert section end*/
                                                });
                                            }
                                        }
                                        initialValues={{
                                            mobile: '',
                                            password: ''
                                        }}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            handleBlur,
                                            values,
                                            touched,
                                            isValid,
                                            errors,
                                        }) => (
                                            <Container>
                                                <Form noValidate onSubmit={handleSubmit}>
                                                    <Form.Group as={Col} md="12" className='py-3' controlId="validationFormikmobile">
                                                        <Form.Label>Mobile</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Mobile"
                                                            name="mobile"
                                                            value={values.mobile}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.mobile}
                                                            isValid={touched.mobile && !errors.mobile}
                                                            autoComplete="off"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.mobile}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md="12" className='py-3' controlId="validationFormik03">
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            placeholder="Password"
                                                            name="password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.password}
                                                            isValid={touched.password && !errors.password}
                                                            autoComplete="off"
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.password}
                                                        </Form.Control.Feedback>
                                                        <div className='text-end'>
                                                            <Link to="/forgot-password">Forgot Password ?</Link>
                                                        </div>


                                                    </Form.Group>
                                                    <div className='py-3'>
                                                        <Button className="w-100" type="submit" variant='dark'>LOGIN</Button>
                                                    </div>
                                                </Form>
                                            </Container>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}