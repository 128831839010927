import axios from 'axios'
import { BehaviorSubject } from 'rxjs'

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser'))
)

export const AuthService = {
  addUser,
  resetPassword,
  sendOTP,
  login,
  logout,
  changePassword,
  changePayPassword,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue () {
    return currentUserSubject.value
  }
}

function addUser (values) {
  return axios.post(`add-user`, values).then()
}

function login (values) {
  return axios.post(`login`, values).then(user => {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    if (user.data.success === true) {
      localStorage.setItem('currentUser', JSON.stringify(user.data.data))
      currentUserSubject.next(user)
    }
    return user
  })
}
function sendOTP (values) {
  return axios.post(`send-otp`, values).then()
}
function resetPassword (values) {
  return axios.post(`reset-password`, values).then()
}

function changePassword (values) {
  return axios.post(`change-password`, values).then()
}
function changePayPassword (values) {
  return axios.post(`change-pay-password`, values).then()
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser')
  currentUserSubject.next(null)
}
