import React, { useState, useEffect } from 'react';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { BsFillTelephoneFill, BsPersonFill } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { ContactService } from 'services';
import './ContactList.scss';

export default function ContactList() {

    const [totalContacts, setTotalContacts] = useState(0);
    const [contacts, setContacts] = useState([]);

    const getContacts = () => {
        let postData = { offset: contacts.length };
        ContactService.list(postData).then(res => {
            setContacts(contacts.concat(res.data.records));
            setTotalContacts(res.data.total_records);
        });
    }


    useEffect(() => {
        getContacts();
    }, [])

    return (
        <>
            {/* Page content start */}

            <Container
                className='contacts-container fixed-header-body'
                id="scrollable-div"
                fluid
            >
                {contacts.length > 0 ?
                    < InfiniteScroll
                        dataLength={contacts.length}
                        next={getContacts}
                        hasMore={totalContacts > contacts.length}
                        scrollableTarget="scrollable-div"
                        loader={<h4 className='text-center'>Loading...</h4>}
                    >
                        <div className='contacts-list'>
                            {contacts.map((contact, index) => {

                                return <>
                                    <Row key={index.toString()} className="contact">
                                        <Col xs={2} className="contact-icon">
                                            {<BsPersonFill size={40} color="green" />}

                                        </Col>
                                        <Col xs={7}>
                                            <div className='contact-type d-flex flex-column'>
                                                <div className="contact-name">
                                                    {contact.full_name}
                                                </div>
                                                <div className="contact-address">{contact.company_name}</div>
                                                <div className="contact-mobile">
                                                    <BsFillTelephoneFill size="10" /> {' '}
                                                    <a href={`tel:${contact.mobile}`}>{contact.mobile}</a>
                                                    {contact.mobile2 &&
                                                        <>
                                                            {', '}
                                                            <a href={`tel:${contact.mobile2}`}>{contact.mobile2}</a>
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                        </Col>
                                        <Col xs={3}>
                                            <div className='contact-detail d-flex flex-column text-end'>
                                                <div className='contact-status'>
                                                    <Badge bg={contact.status === "1" ? "success" : "secondary"}> {contact.status === "1" ? "Active" : "Inactive"}</Badge>
                                                </div>
                                                <div className={`contact-city`}>
                                                    {contact.city}
                                                </div>
                                                <div className={`contact-state`}>
                                                    {contact.state}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>

                            })}

                        </div>
                    </InfiniteScroll> : <div className='no-record-found'>No record found</div>}

            </Container>
        </>
    );
}