import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SalaryList from "components/Salary/SalaryList";
import { EmployeeService } from "services";


export default function Salaries() {
    const params = useParams()
    const employeeId = params.id;

    const [employee, setEmployee] = useState({})

    const getEmployee = () => {
            EmployeeService.detail(employeeId).then(res => {
                setEmployee(res.data);
            });
    }

    useEffect(() => {
        getEmployee()
    }, [])
    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title fixed">{employee.full_name} Salary History</h2>
                <SalaryList />
            </section>
        </>
    );
}