import axios from 'axios'

export const EmployeeSalaryService = {
  salaryGenerate,
  salaryList,
  salaryCalculation
}

function salaryGenerate (data) {
  return axios.post(`employee-salaries/generate`, data).then()
}
function salaryList (data) {
  return axios.post(`employee-salaries/list`, data).then()
}

function salaryCalculation (data) {
  return axios.post(`employee-salaries/salary-calculation`, data).then()
}