import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import './ProtectedLayout.scss'
import ProtectedLayoutFooter from './ProtectedLayoutFooter'

const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="page-container" id="protected-layout">
      {outlet}
      <ProtectedLayoutFooter />
    </div>
  );
};

export default ProtectedLayout;