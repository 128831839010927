import PaymentList from "components/Payment/PaymentList.jsx";


export default function Payments() {

    return (
        <>
            <section className="without-header-page-body">
                <h2 className="page-title fixed">Payments</h2>
                <PaymentList />
            </section>
        </>
    );
}