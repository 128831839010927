import axios from 'axios'

export const RouteService = {
  list,
  allRoutes,
  add,
  detail,
  addEditLoad,
  loads,
  allRouteLoads,
  load,
  payments,
  analysis
}

function list (data) {
  return axios.post(`routes/list`, data).then()
}
function allRoutes () {
  return axios.get(`routes/all-routes`).then()
}
function add (data) {
  return axios.post(`routes/add`, data).then()
}
function detail (id) {
  return axios.get(`routes/route/${id}`).then()
}
function addEditLoad (data) {
  return axios.post(`routes/add-edit-load`, data).then()
}
function loads (data) {
  return axios.post(`routes/loads`, data).then()
}
function allRouteLoads (routeId) {
  return axios.get(`routes/${routeId}/all-loads`).then()
}
function load (id) {
  return axios.post(`routes/loads/${id}`).then()
}
function payments (data) {
  return axios.post(`routes/payments`, data).then()
}
function analysis (data) {
  return axios.post(`routes/analysis`, data).then()
}
