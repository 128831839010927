import React from 'react'
import { Routes, Route } from 'react-router-dom'

//import main components
import ProtectedLayout from 'components/layouts/ProtectedLayout'
import Dashboard from 'pages/Dashboard'
import Login from 'pages/Login'
import Profile from 'pages/Profile'
import ForgotPassword from 'pages/ForgotPassword'
import PageNotFound from 'pages/PageNotFound'
import Payments from 'pages/Payments'
import AddPayment from 'pages/AddPayment'
import VehicleRoutes from 'pages/VehicleRoutes'
import AddVehicleRoute from 'pages/AddVehicleRoute'
import VehicleRoute from 'pages/VehicleRoute'
import Employees from 'pages/Employees'
import Salaries from 'pages/Salaries'
import AddEditEmployee from 'pages/AddEditEmployee'
import Contacts from 'pages/Contacts'
import AddContact from 'pages/AddContact'
import AddEditVehicleRouteLoad from 'pages/AddEditVehicleRouteLoad'
import GenerateSalary from 'pages/GenerateSalary'

const Router = () => (
  <Routes>
    <Route path='/login' element={<Login />} />
    <Route path='/forgot-password' element={<ForgotPassword />} />

    <Route element={<ProtectedLayout />}>
      <Route path='/' element={<Dashboard />} />
      <Route path='/payments' element={<Payments />} />
      <Route path='/add-payment' element={<AddPayment />} />
      <Route path='/routes' element={<VehicleRoutes />} />
      <Route path='/add-route' element={<AddVehicleRoute />} />
      <Route path='/routes/:id' element={<VehicleRoute />} />
      <Route path='/routes/:routeId/add-load' element={<AddEditVehicleRouteLoad />} />
      <Route path='/routes/:routeId/edit-load/:loadId' element={<AddEditVehicleRouteLoad />} />
      <Route path='/employees' element={<Employees />} />
      <Route path='/add-employee' element={<AddEditEmployee />} />
      <Route path='/edit-employee/:id' element={<AddEditEmployee />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/employee-salaries/:id' element={<Salaries />} />
      <Route path='/generate-salary' element={<GenerateSalary />} />
      <Route path='/contacts' element={<Contacts />} />
      <Route path='/add-contact' element={<AddContact />} />
    </Route>
    <Route path='*' element={PageNotFound} />
  </Routes>
)

export default Router
